import React, { useState, useRef } from 'react';
import styles from './mp4-video-player.module.scss';
import classNames from 'classnames';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import SegmentImpression from '~/app/components/segment-impression';

interface Props {
  videoSource: {
    mp4: string;
    webm: string;
  };
  instagramLink: string;
  hideOnMobile: boolean;
  videoMaxWidth?: number;
  videoMaxHeight?: number;
  videoAutoplay?: boolean;
  videoControls?: boolean;
  videoMuted?: boolean;
  videoLoop?: boolean;
  videoPoster?: string;
  videoTrack?: string;
  videoTrackKind?: string;
  analyticsIndex?: number;
}

const defaultDimensions = {
  height: '232px',
  width: '500px',
};

const MP4VideoPlayer = ({
  videoSource,
  instagramLink,
  hideOnMobile,
  videoMaxWidth,
  videoMaxHeight,
  videoAutoplay = false,
  videoControls = false,
  videoMuted = false,
  videoLoop = false,
  videoPoster,
  videoTrack,
  videoTrackKind,
  analyticsIndex,
}: Props): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { trackSegmentTrackEvent } = useAnalytics();
  const handleAnalyticsPlay = () => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: `ig_video_${analyticsIndex}_play`,
    });
  };
  const handleAnalyticsButtonClick = () => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: `ig_video_${analyticsIndex}_button_click`,
    });
  };

  const handlePlayPause = () => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    if (videoEl.paused || videoEl.ended) {
      videoEl.play();
      handleAnalyticsPlay();
      setIsPlaying(true);
    } else {
      videoEl.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div
      className={classNames([
        styles.container,
        { [styles.hideOnMobile]: hideOnMobile },
      ])}
    >
      <div className={styles.videoContainer}>
        <video
          ref={videoRef}
          style={{
            maxWidth: videoMaxWidth || defaultDimensions.width,
            maxHeight: videoMaxHeight || defaultDimensions.height,
            width: '100%',
            height: '100%',
            borderRadius: '16px',
          }}
          autoPlay={videoAutoplay}
          controls={videoControls}
          muted={videoMuted}
          loop={videoLoop}
          poster={videoPoster}
          preload="metadata"
          onClick={handlePlayPause}
        >
          <source src={videoSource.mp4} type="video/mp4" />
          <source src={videoSource.webm} type="video/webm" />
          {videoTrack && (
            <track
              src={videoTrack}
              kind={videoTrackKind}
              srcLang="en"
              label="English"
            />
          )}
        </video>
        <button
          className={isPlaying ? styles.hidePlayButton : styles.videoPlayButton}
          aria-label={isPlaying ? 'Pause video' : 'Play video'}
          onClick={handlePlayPause}
        ></button>
      </div>
      <div className={styles.instagramContainer}>
        <SegmentImpression
          eventName="Element Impression"
          eventProps={{
            entity_name: `ig_video_${analyticsIndex}_button_view`,
          }}
        >
          <a
            href={instagramLink}
            target="_blank"
            className={styles.instagramLink}
            onClick={() => handleAnalyticsButtonClick()}
          >
            <span>Watch on Instagram</span>
          </a>
        </SegmentImpression>
      </div>
    </div>
  );
};

export default MP4VideoPlayer;
