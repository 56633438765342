import { BASE_IMAGE_URL } from '../../constants';

export const VerticalsEnum = {
  CreditCards: 'credit_cards',
  Insurance: 'insurance',
  Mortgages: 'mortgages',
  PersonalLoans: 'personal_loans',
  business_loans: 'business_loans',
  Banking: 'banking',
  Investing: 'investing',
  StudentLoans: 'student_loans',
};

export const SHOPPING_TABS = [
  VerticalsEnum.CreditCards,
  VerticalsEnum.Insurance,
  VerticalsEnum.Mortgages,
  VerticalsEnum.PersonalLoans,
  VerticalsEnum.business_loans,
  VerticalsEnum.Banking,
  VerticalsEnum.Investing,
  VerticalsEnum.StudentLoans,
];

export const SHOPPING_TABS_CONTENT = {
  [VerticalsEnum.CreditCards]: {
    label: 'CREDIT CARDS',
    backgroundColor: '#f9fff9',
    mainText: 'Our Nerds researched 400+ credit cards so you don’t have to.',
    cta: {
      label: 'Compare Credit Cards',
      url: 'https://www.nerdwallet.com/the-best-credit-cards?p_src=homepage',
    },
    images: {
      desktopURL: `${BASE_IMAGE_URL}/heroes/shopping-1440-credit-cards-2x.png`,
      tabletURL: `${BASE_IMAGE_URL}/heroes/shopping-768-credit-cards-2x.png`,
      mobileURL: `${BASE_IMAGE_URL}/heroes/shopping-375-credit-card-2x.png`,
      alt: 'Credit Card Hero Image',
    },
  },
  [VerticalsEnum.Insurance]: {
    label: 'INSURANCE',
    backgroundColor: '#f2fff2',
    mainText:
      'Our Nerds researched 700+ auto insurance companies so you don’t have to.',
    cta: {
      label: 'Compare Car Insurance',
      url: 'https://nerdwallet.com/insurance/car-insurance',
    },
    images: {
      desktopURL: `${BASE_IMAGE_URL}/heroes/shopping-1440-insurance-2x.png`,
      tabletURL: `${BASE_IMAGE_URL}/heroes/shopping-768-insurance-2x.png`,
      mobileURL: `${BASE_IMAGE_URL}/heroes/shopping-375-insurance-2x.png`,
      alt: 'Insurance Hero Image',
    },
  },
  [VerticalsEnum.Mortgages]: {
    label: 'MORTGAGES',
    backgroundColor: '#DCF5E9',
    mainText: 'Our Nerds researched 50+ mortgage lenders so you don’t have to.',
    cta: {
      label: 'Compare Mortgage Rates',
      url: 'https://www.nerdwallet.com/best/mortgages/mortgage-lenders',
    },
    images: {
      desktopURL: `${BASE_IMAGE_URL}/heroes/shopping-1440-mortgage-2x.png`,
      tabletURL: `${BASE_IMAGE_URL}/heroes/shopping-768-mortgage-2x.png`,
      mobileURL: `${BASE_IMAGE_URL}/heroes/shopping-375-mortgage-2x.png`,
      alt: 'Mortgages Hero Image',
    },
  },
  [VerticalsEnum.PersonalLoans]: {
    label: 'PERSONAL LOANS',
    backgroundColor: '#CEF0E1',
    mainText:
      'Our Nerds researched 35+ personal loan lenders so you don’t have to.',
    cta: {
      label: 'Compare Personal Loans',
      url: 'https://www.nerdwallet.com/personal-loans',
    },
    images: {
      desktopURL: `${BASE_IMAGE_URL}/heroes/shopping-1440-personal-loans-2x.png`,
      tabletURL: `${BASE_IMAGE_URL}/heroes/shopping-768-personal-loans-2x.png`,
      mobileURL: `${BASE_IMAGE_URL}/heroes/shopping-375-personal-loans-2x.png`,
      alt: 'Personal Loans Hero Image',
    },
  },
  [VerticalsEnum.business_loans]: {
    label: 'Business Loans',
    backgroundColor: '#bfebda',
    mainText:
      'Our Nerds researched 80+ business loan options, so you don’t have to.',
    cta: {
      label: 'Compare Business Loans',
      url: 'https://www.nerdwallet.com/small-business-loans',
    },
    images: {
      desktopURL: `${BASE_IMAGE_URL}/heroes/shopping-1440-small-business-2x.png`,
      tabletURL: `${BASE_IMAGE_URL}/heroes/shopping-768-small-business-2x.png`,
      mobileURL: `${BASE_IMAGE_URL}/heroes/shopping-375-small-business-2x.png`,
      alt: 'Small Business Hero Image',
    },
  },
  [VerticalsEnum.Banking]: {
    label: 'BANKING',
    backgroundColor: '#b1e6d2',
    mainText:
      'Our Nerds researched 100+ financial institutions so you don’t have to.',
    cta: {
      label: 'Compare Bank Accounts',
      url: 'https://www.nerdwallet.com/best/banking/high-yield-online-savings-accounts',
    },
    images: {
      desktopURL: `${BASE_IMAGE_URL}/heroes/shopping-1440-banking-2x.png`,
      tabletURL: `${BASE_IMAGE_URL}/heroes/shopping-768-banking-2x.png`,
      mobileURL: `${BASE_IMAGE_URL}/heroes/shopping-375-banking-2x.png`,
      alt: 'Banking Hero Image',
    },
  },
  [VerticalsEnum.Investing]: {
    label: 'INVESTING',
    backgroundColor: '#a3e1ca',
    mainText:
      'Our Nerds researched 60+ investment account providers so you don’t have to.',
    cta: {
      label: 'Compare Online Brokers',
      url: 'https://nerdwallet.com/best/investing/online-brokers-for-stock-trading',
    },
    images: {
      desktopURL: `${BASE_IMAGE_URL}/heroes/shopping-1440-investing-2x.png`,
      tabletURL: `${BASE_IMAGE_URL}/heroes/shopping-768-investing-2x.png`,
      mobileURL: `${BASE_IMAGE_URL}/heroes/shopping-375-investing-2x.png`,
      alt: 'Investing Hero Image',
    },
  },
  [VerticalsEnum.StudentLoans]: {
    label: 'STUDENT LOANS',
    backgroundColor: '#95dcc3',
    mainText:
      'Our Nerds researched 35+ student loan lenders so you don’t have to.',
    cta: {
      label: 'Compare Student Loans',
      url: 'https://www.nerdwallet.com/best/loans/student-loans/private-student-loans',
    },
    images: {
      desktopURL: `${BASE_IMAGE_URL}/heroes/shopping-1440-student-loans-2x.png`,
      tabletURL: `${BASE_IMAGE_URL}/heroes/shopping-768-student-loans-2x.png`,
      mobileURL: `${BASE_IMAGE_URL}/heroes/shopping-375-student-loans-2x.png`,
      alt: 'Student Loans Hero Image',
    },
  },
};

/** zIndexes are defined by the selected tab.
 * For example: when PL is selected, Mortgages is ahead of Insurance and this is ahead of CC,
 *   but the tabs at the right of PL follows another pattern: SMB -> Banking -> Investing -> SL.
 */
export const SELECTED_TAB_Z_INDEX_VALUES = {
  [VerticalsEnum.CreditCards]: {
    [VerticalsEnum.CreditCards]: 100,
    [VerticalsEnum.Insurance]: 90,
    [VerticalsEnum.Mortgages]: 80,
    [VerticalsEnum.PersonalLoans]: 70,
    [VerticalsEnum.business_loans]: 60,
    [VerticalsEnum.Banking]: 50,
    [VerticalsEnum.Investing]: 40,
    [VerticalsEnum.StudentLoans]: 30,
  },
  [VerticalsEnum.Insurance]: {
    [VerticalsEnum.CreditCards]: 90,
    [VerticalsEnum.Insurance]: 100,
    [VerticalsEnum.Mortgages]: 80,
    [VerticalsEnum.PersonalLoans]: 70,
    [VerticalsEnum.business_loans]: 60,
    [VerticalsEnum.Banking]: 50,
    [VerticalsEnum.Investing]: 40,
    [VerticalsEnum.StudentLoans]: 30,
  },
  [VerticalsEnum.Mortgages]: {
    [VerticalsEnum.CreditCards]: 80,
    [VerticalsEnum.Insurance]: 90,
    [VerticalsEnum.Mortgages]: 100,
    [VerticalsEnum.PersonalLoans]: 70,
    [VerticalsEnum.business_loans]: 60,
    [VerticalsEnum.Banking]: 50,
    [VerticalsEnum.Investing]: 40,
    [VerticalsEnum.StudentLoans]: 30,
  },
  [VerticalsEnum.PersonalLoans]: {
    [VerticalsEnum.CreditCards]: 70,
    [VerticalsEnum.Insurance]: 80,
    [VerticalsEnum.Mortgages]: 90,
    [VerticalsEnum.PersonalLoans]: 100,
    [VerticalsEnum.business_loans]: 60,
    [VerticalsEnum.Banking]: 50,
    [VerticalsEnum.Investing]: 40,
    [VerticalsEnum.StudentLoans]: 30,
  },
  [VerticalsEnum.business_loans]: {
    [VerticalsEnum.CreditCards]: 60,
    [VerticalsEnum.Insurance]: 70,
    [VerticalsEnum.Mortgages]: 80,
    [VerticalsEnum.PersonalLoans]: 90,
    [VerticalsEnum.business_loans]: 100,
    [VerticalsEnum.Banking]: 50,
    [VerticalsEnum.Investing]: 40,
    [VerticalsEnum.StudentLoans]: 30,
  },
  [VerticalsEnum.Banking]: {
    [VerticalsEnum.CreditCards]: 50,
    [VerticalsEnum.Insurance]: 60,
    [VerticalsEnum.Mortgages]: 70,
    [VerticalsEnum.PersonalLoans]: 80,
    [VerticalsEnum.business_loans]: 90,
    [VerticalsEnum.Banking]: 100,
    [VerticalsEnum.Investing]: 40,
    [VerticalsEnum.StudentLoans]: 30,
  },
  [VerticalsEnum.Investing]: {
    [VerticalsEnum.CreditCards]: 40,
    [VerticalsEnum.Insurance]: 50,
    [VerticalsEnum.Mortgages]: 60,
    [VerticalsEnum.PersonalLoans]: 70,
    [VerticalsEnum.business_loans]: 80,
    [VerticalsEnum.Banking]: 90,
    [VerticalsEnum.Investing]: 100,
    [VerticalsEnum.StudentLoans]: 30,
  },
  [VerticalsEnum.StudentLoans]: {
    [VerticalsEnum.CreditCards]: 30,
    [VerticalsEnum.Insurance]: 40,
    [VerticalsEnum.Mortgages]: 50,
    [VerticalsEnum.PersonalLoans]: 60,
    [VerticalsEnum.business_loans]: 70,
    [VerticalsEnum.Banking]: 80,
    [VerticalsEnum.Investing]: 90,
    [VerticalsEnum.StudentLoans]: 100,
  },
};
