export const articles = [
  {
    id: 1685207,
    title: 'Trump’s Plans for the Economy in His First 100 Days',
    imageUrl:
      'https://www.nerdwallet.com/assets/blog/wp-content/uploads/2025/01/GettyImages-2162550753-1-770x462.jpg',
    author: 'Anna Helhoski',
    authorImageUrl:
      'https://www.nerdwallet.com/assets/blog/wp-content/uploads/2022/05/Screenshot-2024-03-26-at-12.18.29 PM-160x160.png',
    authorLink: 'https://www.nerdwallet.com/author/anna-helhoski',
    date: 'Jan 22, 2025',
    readTime: '5 min read',
    link: 'https://www.nerdwallet.com/article/finance/trump-first-hundred-days',
  },
  {
    id: 1687482,
    title: '5 Credit Card Trends to Watch for in 2025',
    imageUrl:
      'https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/04/GettyImages-1086590348-2400x1440.jpg',
    author: 'Sara Rathner',
    authorImageUrl:
      'https://www.nerdwallet.com/tachyon/2024/09/24-065-6204_Sara-Rathner_background-e1726000043664.png?resize=160%2C160',
    authorLink: 'https://www.nerdwallet.com/author/sara-rathner',
    date: 'Jan 16, 2025',
    readTime: '4 min read',
    link: 'https://www.nerdwallet.com/article/credit-cards/credit-cards-trends-to-watch-2025',
  },
  //   TODO: Add this article back in when it is live
  //   {
  //     id: , //TODO: get real article ID
  //     title: 'What Is Layaway, and Should You Use It for Black Friday Shopping?',
  //     imageUrl:
  //       'https://stage.nerdwallet.biz/assets/blog/wp-content/uploads/2024/10/GettyImages-1162215639.jpg',
  //     author: 'Lauren Schwahn',
  //     authorImageUrl:
  //       'https://stage.nerdwallet.biz/assets/blog/wp-content/uploads/2024/12/profile-pic.jpg',
  //     authorLink: 'https://www.nerdwallet.com/author/lauren-schwahn',
  //     date: 'Nov 25, 2024',
  //     readTime: '3 min read',
  //     link: 'https://www.nerdwallet.com/article/credit-cards/credit-cards-trends-to-watch-2025',
  //   },
  {
    id: 1682607,
    title: '4 Steps to Becoming Debt-Free in 2025',
    imageUrl:
      'https://www.nerdwallet.com/assets/blog/wp-content/uploads/2025/01/GettyImages-1326374995-2400x1440.jpg',
    author: 'Jackie Veling',
    authorImageUrl:
      'https://www.nerdwallet.com/tachyon/2020/09/Freelance-5-Cropped2.jpg?resize=160%2C160',
    authorLink: 'https://www.nerdwallet.com/author/jackie-veling',
    date: 'Jan 6, 2025',
    readTime: '3 min read',
    link: 'https://www.nerdwallet.com/article/loans/personal-loans/steps-to-becoming-debt-free',
  },
  {
    id: 1554650,
    title: 'Subscriptions Are Hard to Cancel and Easy to Forget — by Design',
    imageUrl:
      'https://www.nerdwallet.com/tachyon/2024/05/GettyImages-485441768.jpg?resize=2400%2C1440',
    author: 'Tommy Tindall',
    authorImageUrl:
      'https://www.nerdwallet.com/tachyon/2021/12/IMG_3114_edited.jpg?resize=160%2C160',
    authorLink: 'https://www.nerdwallet.com/author/tommy-tindall',
    date: 'Oct 16, 2024',
    readTime: '3 min read',
    link: 'https://www.nerdwallet.com/article/finance/subscriptions-are-hard-to-cancel-and-easy-to-forget-by-design',
  },
  {
    id: 1595571,
    title: 'The Best Car and Home Insurance Bundles for 2025',
    author: 'Sarah Schlichter',
    authorImageUrl:
      'https://www.nerdwallet.com/tachyon/2020/06/Sarah-Schlichter-headshot-square.jpg?resize=160%2C160',
    authorLink: 'https://www.nerdwallet.com/author/sarah-schlichter',
    date: 'Jan 2, 2025',
    readTime: '4 min read',
    link: 'https://www.nerdwallet.com/p/best/insurance/home-auto-insurance-bundle',
  },
  {
    id: 1674881,
    title: 'Buying a House in 2025: Your How-To Guide',
    imageUrl:
      'https://stage.nerdwallet.biz/assets/blog/wp-content/uploads/2024/10/GettyImages-1162215639.jpg',
    author: 'Abby Badach Doyle',
    authorImageUrl:
      'https://www.nerdwallet.com/tachyon/2022/06/Abby-Badach-Doyle.jpg?resize=160%2C160',
    authorLink: 'https://www.nerdwallet.com/author/abby-badach-doyle',
    date: 'Dec 17, 2024',
    readTime: '4 min read',
    link: 'https://www.nerdwallet.com/article/mortgages/buying-a-house-in-2025',
  },
  {
    id: 853248,
    title: 'Best Ways to Invest Money in 2025',
    imageUrl:
      'https://stage.nerdwallet.biz/assets/blog/wp-content/uploads/2024/10/GettyImages-1162215639.jpg',
    author: 'Chris Davis',
    authorImageUrl:
      'https://www.nerdwallet.com/tachyon/2020/03/Chris-Davis.jpg?resize=160%2C160',
    authorLink: 'https://www.nerdwallet.com/author/chris-davis',
    date: 'Jan 16, 2025',
    readTime: '4 min read',
    link: 'https://www.nerdwallet.com/article/investing/the-best-investments-right-now',
  },
  {
    id: 878585,
    title: 'Roth IRA Contribution Limits and Income Limits 2024 and 2025',
    imageUrl:
      'https://stage.nerdwallet.biz/assets/blog/wp-content/uploads/2024/10/GettyImages-1162215639.jpg',
    author: 'June Sham',
    authorImageUrl:
      'https://www.nerdwallet.com/tachyon/2023/04/336189831_5685987188176353_2746243523277238701_n-e1681950652588.jpg?resize=160%2C160',
    authorLink: 'https://www.nerdwallet.com/author/june-sham',
    date: 'Jan 10, 2025',
    readTime: '3 min read',
    link: 'https://www.nerdwallet.com/article/investing/roth-ira-contribution-limits',
  },
  {
    id: 844671,
    title: '2024 and 2025 Tax Brackets and Federal Income Tax Rates',
    imageUrl:
      'https://stage.nerdwallet.biz/assets/blog/wp-content/uploads/2024/10/GettyImages-1162215639.jpg',
    author: 'Sabrina Parys',
    authorImageUrl:
      'https://www.nerdwallet.com/tachyon/2021/09/Sabrina_bio-copy.jpg?resize=160%2C160',
    authorLink: 'https://www.nerdwallet.com/author/sabrina-parys',
    date: 'Jan 2, 2025',
    readTime: '3 min read',
    link: 'https://www.nerdwallet.com/article/taxes/federal-income-tax-brackets',
  },
];
