import { useRef } from 'react';
import { CenteredContainer } from '@nerdwallet/currency';
import Image from '@nerdwallet/react-image';
import Carousel from '@nerdwallet/react-carousel';
import FolderTabContainer from '../folder-tab-container/folder-tab-container';
import CarouselArrow from './testimonials-carousel-arrow';
import TestimonialCard from './testimonials-card';
import { testimonials } from './testimonials-data';
import styles from './testimonials.module.scss';
import './testimonials-carousel.scss';
import SegmentImpression from '../../segment-impression';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import { BASE_IMAGE_URL } from '../constants';

const TestimonialTile = ({
  text,
  author,
}: {
  text: string;
  author: string;
}) => (
  <div className={styles.testimonialTile}>
    <p className={styles.testimonialTileText}>{text}</p>
    <p className={styles.testimonialTileAuthor}>{author}</p>
  </div>
);

const carouselSettings = {
  arrows: false,
  infinite: true,
  slidesToShow: 4,
  variableWidth: true,
  dots: true,
  suppressHydrationWarning: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Testimonials() {
  let sliderRef: any = useRef(null);

  const handlePrevSlide = () => {
    sliderRef.slider?.slickPrev();
  };

  const handleNextSlide = () => {
    sliderRef.slider?.slickNext();
  };

  return (
    <FolderTabContainer
      tabBackgroundColor="#006642"
      contentBackgroundColor="#006642"
      negativeTopMarginValue={31}
    >
      <CenteredContainer>
        <div className={styles.container}>
          <h5 className={styles.title}>
            See all your money in one place with the NerdWallet app
          </h5>
          <div className={styles.testimonialsContent}>
            {/* NerdWallet app star rating */}
            <div className={styles.testimonialsLeftContainer}>
              <div className={styles.testimonialsCardContainer}>
                <TestimonialCard
                  title="4.8"
                  subtitle="average star rating"
                  titleStar
                />
                <TestimonialCard
                  title="100k"
                  subtitle="5-star ratings"
                  subtitleStar
                  backgroundType="grid"
                />
                <TestimonialCard title="7m" subtitle="total downloads" />
              </div>
              <div className={styles.QRWrapper}>
                <SegmentImpression
                  eventName="Element Impression"
                  eventProps={{
                    entity_name:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.qr_code.entity_name,
                    location: HOMEPAGE_SEGMENT_INSTRUMENTATION.qr_code.location,
                    sub_location:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.qr_code.sub_location,
                  }}
                >
                  <div className={styles.QRCodeContainer}>
                    <h6 className={styles.QRCodeText}>
                      Scan to download the app
                    </h6>
                    <Image
                      alt="QR code for the NerdWallet app"
                      backgroundColor="transparent"
                      naturalHeight={150}
                      naturalWidth={150}
                      className={styles.QRCodeImage}
                      src="https://www.nerdwallet.com/cdn/front-page-next/nw-app-download-qr-code-app.png"
                    />
                  </div>
                </SegmentImpression>
              </div>
              {/* Play Store / App Store buttons */}
              <div className={styles.appStoreIconsContainer}>
                <SegmentImpression
                  eventName="Element Impression"
                  eventProps={{
                    entity_name:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.google_play.entity_name,
                    location:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.google_play.location,
                    sub_location:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.google_play.sub_location,
                  }}
                >
                  <a
                    target="_blank"
                    href="https://nerdwallet.onelink.me/3687710914/233a7a41"
                  >
                    <Image
                      alt="Play Store Icon"
                      backgroundColor="transparent"
                      objectFit="contain"
                      naturalHeight={55}
                      naturalWidth={165}
                      className={styles.appStoreIcons}
                      src="https://www.nerdwallet.com/cdn/front-page-next/play-store-button.png"
                    />
                  </a>
                </SegmentImpression>
                <SegmentImpression
                  eventName="Element Impression"
                  eventProps={{
                    entity_name:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.app_store.entity_name,
                    location:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.app_store.location,
                    sub_location:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.app_store.sub_location,
                  }}
                >
                  <a
                    target="_blank"
                    href="https://nerdwallet.onelink.me/3687710914/87a0b5b3"
                  >
                    <Image
                      alt="App Store Icon"
                      backgroundColor="transparent"
                      objectFit="contain"
                      naturalHeight={55}
                      naturalWidth={165}
                      className={styles.appStoreIcons}
                      src="https://www.nerdwallet.com/cdn/front-page-next/app-store-button.png"
                    />
                  </a>
                </SegmentImpression>
              </div>
            </div>
            {/* NerdWallet app screenshot */}
            <div>
              <Image
                alt="NerdWallet app"
                backgroundColor="transparent"
                objectFit="contain"
                objectPosition="bottom"
                naturalHeight={575}
                naturalWidth={600}
                className={styles.QRCodeFeaturedImage}
                src={`${BASE_IMAGE_URL}/heroes/App-1440-2x.png`}
              />
            </div>
          </div>
        </div>
      </CenteredContainer>
      {/* Carousel  */}
      <div className={styles.carouselContainer}>
        <Carousel
          ref={(slider: any) => {
            sliderRef = slider;
          }}
          className="testimonial-carousel"
          {...carouselSettings}
        >
          {testimonials.map((testimonial) => (
            <TestimonialTile
              key={testimonial.id}
              text={testimonial.text}
              author={testimonial.author}
            />
          ))}
        </Carousel>
        <CenteredContainer className={styles.carouselNav}>
          <button
            className={styles.carouselNavButton}
            onClick={handlePrevSlide}
          >
            <CarouselArrow type="prev" />
          </button>
          <button
            className={styles.carouselNavButton}
            onClick={handleNextSlide}
          >
            <CarouselArrow type="next" />
          </button>
        </CenteredContainer>
      </div>
    </FolderTabContainer>
  );
}

export default Testimonials;
