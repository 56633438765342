import React from 'react';
import Image from '@nerdwallet/react-image';
import {
  Tabs,
  Tab,
  TabList,
  TabPanel,
  SHOPPING_TABS,
  SHOPPING_TABS_CONTENT,
} from './ShoppingTabs';
import { Button } from '@nerdwallet/currency';
import styles from './ShoppingModule.module.scss';
import { trackInteraction } from '~/app/lib/analytics/analytics-helpers';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '~/app/lib/analytics/analytics-constants';
import useAnalytics from '~/app/lib/segment/useAnalytics';

const ShoppingModule = (): JSX.Element => {
  const { trackSegmentTrackEvent } = useAnalytics();
  const trackAnalytics = (entityName: string) => {
    trackInteraction({
      entityName: entityName,
      sectionName: analyticsBase.section_name,
      action: 'select',
    });
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison.location,
      interaction_type: 'select',
    });
  };

  return (
    <div>
      <Tabs defaultValue={SHOPPING_TABS[0]}>
        <TabList>
          {SHOPPING_TABS.map((tab, index) => (
            <Tab
              name={tab}
              label={SHOPPING_TABS_CONTENT[tab].label}
              backgroundColor={SHOPPING_TABS_CONTENT[tab].backgroundColor}
              key={`${tab}_${index}`}
            />
          ))}
        </TabList>
        {SHOPPING_TABS.map((tab, index) => {
          const { backgroundColor, mainText, cta, images } =
            SHOPPING_TABS_CONTENT[tab];
          const primaryEntityName = `${analyticsBase.entity_names.primary_cta}_${tab}`;
          return (
            <TabPanel name={tab} key={`${tab}_content_${index}`}>
              <div
                className={styles.card}
                style={{
                  backgroundColor,
                }}
              >
                <div className={styles.cardTextAndCTA}>
                  <span className={styles.cardText}>{mainText}</span>
                  <Button
                    primary
                    href={cta?.url}
                    onClick={() => trackAnalytics(primaryEntityName)}
                  >
                    {cta?.label}
                  </Button>
                </div>
                <div className={styles.heroContainer}>
                  <Image
                    src={images?.desktopURL}
                    alt={images?.alt}
                    backgroundColor={backgroundColor}
                    sources={[
                      {
                        srcSet: `${images.desktopURL} 536w`,
                        sizes: '100vw',
                        media: '(min-width: 1280px)',
                      },
                      {
                        srcSet: `${images.tabletURL} 1279w`,
                        sizes: '100vw',
                        media: '(min-width: 768px) and (max-width: 1279px)',
                      },
                      {
                        srcSet: `${images.mobileURL} 720w`,
                        sizes: '100vw',
                        media: '(max-width: 767px)',
                      },
                    ]}
                  />
                </div>
              </div>
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};

export default ShoppingModule;
