import Image from '@nerdwallet/react-image';
import Carousel from '@nerdwallet/react-carousel';
import FolderTabContainer from '../folder-tab-container/folder-tab-container';
import styles from './articles.module.scss';
import { CenteredContainer } from '@nerdwallet/currency';
import { articles } from './article-data';
import SegmentImpression from '~/app/components/segment-impression';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import useAnalytics from '~/app/lib/segment/useAnalytics';

const carouselSettings = {
  arrows: true,
  slidesToShow: 3,
  variableWidth: true,
  dots: true,
  suppressHydrationWarning: true,
};

type ArticleCardProps = {
  id: number;
  title: string;
  imageUrl?: string;
  authorImageUrl: string;
  articleLink: string;
  authorLink: string;
  author: string;
  date: string;
  readTime: string;
};

const ArticleCard = ({
  id,
  title = '',
  imageUrl = '',
  authorImageUrl = '',
  articleLink = '',
  authorLink = '',
  author = '',
  date = '',
  readTime = '',
}: ArticleCardProps) => {
  const { trackSegmentTrackEvent } = useAnalytics();

  const handleAnalytics = () => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: `breaking_news_feed_featured_${id}`,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.location,
      action: 'select',
    });
  };

  return (
    <div className={styles.articleCard}>
      <div className={styles.articleContent}>
        <a
          href={articleLink}
          onClick={() => handleAnalytics()}
          className={styles.articleLinks}
        >
          <h3 className={styles.articleTitle}>{title}</h3>
        </a>
        <div className={styles.authorInfo}>
          {/* TODO: Add author image link */}
          <Image
            className={styles.authorImage}
            objectFit="cover"
            backgroundColor="transparent"
            src={authorImageUrl}
            alt="author image"
          />
          <a href={authorLink} className={styles.articleLinks}>
            <p className={styles.authorName}>by {author}</p>
          </a>
        </div>
        <div className={styles.articleMeta}>
          <span className={styles.articleMetaText}>{date}</span>
          <span className={styles.articleMetaText}>{readTime}</span>
        </div>
      </div>

      <a
        href={articleLink}
        onClick={() => handleAnalytics()}
        className={styles.articleImageLinkWrapper}
      >
        <Image
          className={styles.articleImage}
          objectFit="cover"
          backgroundColor="transparent"
          src={imageUrl}
          alt="article image"
        />
      </a>
    </div>
  );
};

type CarouselCardProps = {
  id: number;
  title: string;
  imageUrl?: string;
  author: string;
  authorImageUrl: string;
  authorLink: string;
  date: string;
  readTime: string;
  link: string;
};

const CarouselCard = ({
  id,
  title,
  author,
  authorImageUrl,
  date,
  readTime,
  link,
  authorLink,
}: CarouselCardProps) => {
  const { trackSegmentTrackEvent } = useAnalytics();

  const handleAnalytics = () => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: `breaking_news_feed_sidebar_${id}`,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.location,
      action: 'select',
    });
  };

  return (
    <div className={styles.carouselCard}>
      <div className={styles.carouselContent}>
        <a
          href={link}
          onClick={() => handleAnalytics()}
          className={styles.articleLinks}
        >
          <h3 className={styles.carouselTitle}>{title}</h3>
        </a>
        <div className={styles.carouselAuthorInfo}>
          <Image
            className={styles.authorImage}
            objectFit="cover"
            backgroundColor="transparent"
            src={authorImageUrl}
            alt="author image"
          />
          <a href={authorLink} className={styles.articleLinks}>
            <p className={styles.authorName}>by {author}</p>
          </a>
        </div>
        <div className={styles.carouselArticleMeta}>
          <span className={styles.carouselArticleMetaText}>{date}</span>
          <span className={styles.carouselArticleMetaText}>{readTime}</span>
        </div>
      </div>
    </div>
  );
};

const renderCarouselCards = (articles: CarouselCardProps[]) =>
  articles
    .slice(1)
    .map((article) => <CarouselCard {...article} key={article.id} />);

function Articles() {
  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.entity_name,
        location: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.location,
      }}
    >
      <div className={styles.desktopContainer} id="desktop-article">
        <FolderTabContainer
          tabBackgroundColor="#40AD87"
          negativeTopMarginValue={30}
        >
          <div className={styles.backgroundContainer}>
            {/* Desktop Content */}
            <CenteredContainer>
              <FolderTabContainer
                tabBackgroundColor="#B7ECDA"
                contentBackgroundColor="#B7ECDA"
                contentClassName={styles.desktopArticleContainer}
                roundedCorners
              >
                <div className={styles.articleHeader}>
                  <h3 className={styles.articleHeaderTitle}>
                    Stay up to date with the latest financial news from the
                    Nerds
                  </h3>

                  <a
                    className={styles.articleHeaderLink}
                    href="https://www.nerdwallet.com/h/news/financial-news"
                  >
                    View all news
                  </a>
                </div>

                <ArticleCard
                  id={articles[0].id}
                  title={articles[0].title}
                  imageUrl={articles[0].imageUrl}
                  authorImageUrl={articles[0].authorImageUrl}
                  articleLink={articles[0].link}
                  authorLink={articles[0].authorLink}
                  author={articles[0].author}
                  date={articles[0].date}
                  readTime={articles[0].readTime}
                />

                <div className={styles.carouselContainer}>
                  <Carousel className="articles-carousel" {...carouselSettings}>
                    {renderCarouselCards(articles)}
                  </Carousel>
                </div>
              </FolderTabContainer>
            </CenteredContainer>
          </div>
        </FolderTabContainer>
      </div>

      {/* Mobile Content */}
      <div className={styles.mobileContainer}>
        <FolderTabContainer
          tabBackgroundColor="#81D6B8"
          negativeTopMarginValue={30}
        >
          <div className={styles.backgroundContainer}>
            <CenteredContainer>
              <div className={styles.articleHeader}>
                <h3 className={styles.articleHeaderTitle}>
                  Stay up to date with the latest financial news from the Nerds
                </h3>
              </div>
              <FolderTabContainer
                tabBackgroundColor="#E3FAF2"
                contentBackgroundColor="#E3FAF2"
                roundedCorners
                folderTabWidthPercentage={50}
              >
                <ArticleCard
                  id={articles[0].id}
                  title={articles[0].title}
                  imageUrl={articles[0].imageUrl}
                  authorImageUrl={articles[0].authorImageUrl}
                  articleLink={articles[0].link}
                  authorLink={articles[0].authorLink}
                  author={articles[0].author}
                  date={articles[0].date}
                  readTime={articles[0].readTime}
                />
              </FolderTabContainer>
            </CenteredContainer>
            <div className={styles.carouselContainer}>
              <Carousel
                className="articles-carousel"
                {...{ ...carouselSettings, slidesToShow: 1.1, arrows: false }}
              >
                {renderCarouselCards(articles)}
              </Carousel>
            </div>
          </div>
        </FolderTabContainer>
      </div>
    </SegmentImpression>
  );
}

export default Articles;
