import classNames from 'classnames';
import CardStar from './testimonials-card-star';
import styles from './testimonials.module.scss';

interface TestimonialCardProps {
  title?: string;
  subtitle?: string;
  titleStar?: boolean;
  subtitleStar?: boolean;
  backgroundType?: 'grid' | 'line';
}

function TestimonialCard({
  title = '',
  subtitle = '',
  titleStar = false,
  subtitleStar = false,
  backgroundType = 'line',
}: TestimonialCardProps) {
  const backgroundStyle = {
    grid: styles.testimonialsCardBackgroundGrid,
    line: styles.testimonialsCardBackgroundLine,
  }; 

  return (
    <div
      className={classNames([
        styles.testimonialsCard,
        backgroundStyle[backgroundType],
      ])}
    >
      <h4 className={styles.testimonialsCardTitle}>
        {title}
        {titleStar && <CardStar />}
      </h4>
      <p className={styles.testimonialsCardSubtitle}>
        {subtitle}
        {subtitleStar && <CardStar />}
      </p>
    </div>
  );
}

export default TestimonialCard;
