'use client';
/**
 * Dependencies
 */
import React from 'react';
import ShoppingModule from '~/app/components/front-page-redesign/shopping-module';
import NerdWalletPlus from '~/app/components/front-page-redesign/nerdwallet-plus/nerdwallet-plus';
import SocialModule from '~/app/components/front-page-redesign/social-module/social-module';
import { Box, CenteredContainer, Text } from '@nerdwallet/currency';
import styles from './front-page-redesign.module.scss';
// @ts-ignore
import initBaseStyles from '@nerdwallet/base-styles/init';
import SegmentTracking from '~/app/lib/segment/SegmentTracking';
import BounceTracking from '~/app/components/bounce-tracking';
import { useInitializeGtm } from '~/app/lib/initializeGtm';
import { getPageTaxonomy } from '~/app/lib/page-taxonomy';
import { usePathname } from 'next/navigation';
import Quiz from '~/app/components/front-page-redesign/quiz';
import Testimonials from '~/app/components/front-page-redesign/testimonials';
import Articles from '~/app/components/front-page-redesign/articles';

// ToDo: should we remove this code and make this a server-side component?
if (typeof window !== 'undefined') {
  initBaseStyles();
}

const FrontPageRedesign = () => {
  // const isAuthenticated = useIsAuthenticated();
  useInitializeGtm({ pageTaxonomy: getPageTaxonomy(usePathname()) ?? [] });

  return (
    <React.Fragment>
      <BounceTracking />
      <SegmentTracking segmentPageName="homepage">
        <Box className={styles.mainWrapper}>
          <CenteredContainer className={styles.shoppingModuleWrapper}>
            <Box className={styles.banner}>
              <Box className={styles.imageWrapper}>
                <div className={styles.image} />
              </Box>
              <Box className={styles.textWrapper}>
                <Text bold>
                  Wondering what a genius like me is doing here? Find out on
                  2.09.
                </Text>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box className={styles.heading}>
                <Text component="h1" bold>
                  <span className={styles.titleSize}>
                    Make smarter decisions with the Nerds by your side
                  </span>
                </Text>
                <div className={styles.subHeading}>
                  <Text component="h2" size="large">
                    Compare our top picks side by side. Click a tab to get
                    started.
                  </Text>
                </div>
              </Box>
              <ShoppingModule />
            </Box>
          </CenteredContainer>
          <Quiz />
          <NerdWalletPlus showNerdWalletPlus showBestOfAwards />
          <SocialModule />
          <Articles />
          <Testimonials />
        </Box>
      </SegmentTracking>
    </React.Fragment>
  );
};

export default FrontPageRedesign;
